import React from 'react';
import {authenticationService} from "../scripts/authentication.service";
import Header from "../header/header";
import {Link} from "react-router-dom";
import {
    TextField,
    Button,
    LinearProgress,
    CardMedia,
    InputAdornment,
    ToggleButtonGroup,
    ToggleButton,
    Card,
    Tooltip,
    CardActionArea,
    CardContent,
    CardActions,
    Pagination,
    FormControl,
    InputLabel, Select, MenuItem
} from '@mui/material'
import {withSnackbar} from "notistack";
import {cf} from "../scripts/custom-fetch";
import SearchIcon from "@mui/icons-material/Search";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";

class PlaylistEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            playlist: null,
            music: [],
            stims: [],
            feed: [],
            currentFeed: [],
            linkedMusic: [],
            linkedStims: [],
            isLoaded: false,
            error: null,
            errors: {},
            open: false,
            posting: false,
            currentSearch: [],
            searchContent: [],
            page: 0,
            currentPage: 1,
            itemsPerPage: 10,
            currentType2: "",
            currentType3: "all",
        };
    }

    getPlaylist() {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':this.state.id})
        };

        cf.get(`${process.env.REACT_APP_API_GET}/music/getPlaylist`, options)
            .then((result) => {
                    this.setState({
                        playlist: result[0]
                    }, () => {this.getPlaylistContent()});
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getPlaylistContent() {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':this.state.id})
        };

        cf.get(`${process.env.REACT_APP_API_GET}/music/getPlaylistContent`, options)
            .then((result) => {
                    this.setState({
                        linkedMusic: result,
                    }, () => {
                        this.getAllMusic()
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getPlaylistStims(yeet) {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':this.state.id})
        };

        cf.get(`${process.env.REACT_APP_API_GET}/music/getPlaylistStim`, options)
            .then((result) => {
                    if (yeet) {
                        this.setState({
                            linkedStims: result,
                        }, () => {
                            this.getAllStims()
                        });
                    } else {
                        this.setState({
                            linkedStims: result,
                        }, () => {
                            this.getAllStims()
                        });
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getAllMusic() {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        cf.get(`${process.env.REACT_APP_API_GET}/music/getAllMusic`, options)
            .then((result) => {
                    this.setState({
                        music: result,
                        searchContent: result
                    }, () => {
                        this.getPlaylistStims()
                    });
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
    }

    getAllStims() {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        cf.get(`${process.env.REACT_APP_API_GET}/music/getAllStim`, options)
            .then((result) => {
                    this.reorder()
                    this.setState({
                        isLoaded: true,
                        stims: result,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    link = (id) => {
        let order
        let apiLink
        if (this.state.page === 0) {
            this.state.linkedMusic > 0 ? order = (parseInt(this.state.linkedMusic.at(-1).order) + 1) : order = 1
            apiLink = "linkMusic"
        } else {
            this.state.linkedStims > 0 ? order = (parseInt(this.state.linkedStims.at(-1).order) + 1) : order = 1
            apiLink = "linkStim"
        }

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'playlist_id':this.state.id,'content_id':id,'order':order})
        };

        cf.get(`${process.env.REACT_APP_API_GET}/music/${apiLink}`, options)
            .then((result) => {
                console.log(result)
                if (this.state.page === 1) {
                    this.getPlaylistStims(true)
                } else {
                    this.getPlaylistContent()
                }
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
    }

    unlink = (id) => {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'playlist_id':this.state.id,'content_id':id})
        };

        let apiLink = "unlinkMusic"
        if (this.state.page === 1) {
            apiLink = "unlinkStim"
        }

        cf.get(`${process.env.REACT_APP_API_GET}/music/${apiLink}`, options)
            .then((result) => {
                    console.log(result)
                    if (this.state.page === 1) {
                        this.getPlaylistStims(true)
                    } else {
                        this.getPlaylistContent()
                    }
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
    }

    reorder = () => {
        this.setState({feed:[...this.state.linkedStims, ...this.state.linkedMusic].sort(function (a, b) {return a.order - b.order;})})
    }

    validate = () => {
        let playlist = this.state.playlist
        let errors = {}
        let valid = true
        if (!playlist.title || playlist.title.length < 1) {
            valid = false
            errors.title = "Title must exist"
        }
        if (escape(playlist.title).length >= 1024) {
            valid = false
            errors.title = "Title too long"
        }

        if (!playlist.name || playlist.name.length < 1) {
            valid = false
            errors.name = "Name must exist"
        }
        if (escape(playlist.name).length >= 1024) {
            valid = false
            errors.name = "Name too long"
        }

        this.setState({errors: errors})
        return valid
    }

    updatePlaylist = () => {
        this.props.enqueueSnackbar('Validating', {variant:"info"});
        if(!this.validate()) {
            this.props.enqueueSnackbar('Validation Failed Please Check Your Inputs', {variant:"error"});
            return
        }
        this.props.enqueueSnackbar('Saving', {variant:"info"});
        this.setState({
            posting: true,
        }, () => {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
                body: JSON.stringify({
                    'id':this.state.id,
                    'playlist':this.state.playlist,
                    'linkedMusic':this.state.linkedMusic,
                    'linkedStims':this.state.linkedStims
                })
            };

            cf.get(`${process.env.REACT_APP_API_POST}/music/updatePlaylist`, options)
                .then((result) => {
                        console.log(result)
                        this.setState({
                            posting: false
                        }, () => {
                            if (!!result) {
                                this.reorder()
                                this.props.enqueueSnackbar('Saved', {variant:"success"});
                            } else {
                                this.props.enqueueSnackbar('Save Failed - Please Try Again', {variant:"warning"});
                            }
                        });
                    },
                    (error) => {
                        console.log(error)
                        this.setState({
                            posting: false
                        }, () => {
                            this.props.enqueueSnackbar('Save Failed - Please Contact an Admin', {variant:"error"});
                        });
                    }
                )
        })
    }

    toggleOpen = () => {
        this.setState({open: !this.state.open})
    }

    searchContent() {
        let results
        if (this.state.page === 0) {
            results = this.state.music
        } else {
            results = this.state.stims
        }

        if (this.state.currentSearch.length !== 0) {
            results = results.filter(obj => {return obj.title.toUpperCase().includes(this.state.currentSearch.toUpperCase())})
        }

        if (this.state.currentType2 === "new") {
            results = results.sort(function (a,b){
                return (b.id - a.id)
            })
        }

        if (this.state.currentType2 === "old") {
            results = results.sort(function (a,b){
                return (a.id - b.id)
            })
        }

        if (this.state.currentType3 === "sti") {
            results = results.filter(obj => {return obj.viewability_tracking})
        }

        if (this.state.currentType3 === "nsti") {
            results = results.filter(obj => {return !obj.viewability_tracking})
        }

        this.setState({searchContent:results})
    }

    componentDidMount() {
        this.getPlaylist()
    }

    handleChange = (event) => {
        let playlist = this.state.playlist;
        playlist[event.target.name] = event.target.value;
        this.setState({playlist: playlist})
    }

    handleOrderChange = (event) => {
        let linkedMusic
        if (this.state.page === 0) {
            linkedMusic = this.state.linkedMusic
        } else {
            linkedMusic = this.state.linkedStims
        }
        let index = linkedMusic.findIndex(x => parseInt(x.id) === parseInt(event.target.name))
        linkedMusic[index].order = event.target.value
        if (this.state.page === 0) {
            this.setState({
                linkedMusic:linkedMusic,
            })
        } else {
            this.setState({
                linkedStims:linkedMusic,
            })
        }
    }

    updateSearchText = (event) => {
        this.setState({
            currentSearch: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    updateSearchType2 = (event) => {
        this.setState({
            currentType2: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    updateSearchType3 = (event) => {
        this.setState({
            currentType3: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    setPages = (event, value) => {
        this.setState({
            currentPage: value
        })
    }

    setPage = (num) => {
        switch (num){
            case 0:
                this.setState({
                    searchContent: this.state.music,
                    currentFeed: this.state.linkedMusic,
                    currentPage: 1,
                    page: num
                })
                break;
            case 1:
                console.log(this.state.linkedStims)
                this.setState({
                    searchContent: this.state.stims,
                    currentFeed: this.state.linkedStims,
                    currentPage: 1,
                    page: num
                })
                break;
            default:
                break;
        }
    }

    render() {
        const { error, errors, isLoaded, playlist, posting, open, currentType2, currentType3, currentPage, itemsPerPage, searchContent, page, currentFeed, feed } = this.state
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = searchContent.slice(indexOfFirstItem, indexOfLastItem)
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            console.log(feed)
            return (
                <>
                    <Header />
                    <div className="social">
                        <div className="top-selector">
                            <div className={page === 0 ? "items selected" : "items"} onClick={ () => this.setPage(0)}>Content</div>
                            <div className={page === 1 ? "items selected" : "items"} onClick={ () => this.setPage(1)}>Promoted</div>
                        </div>
                        <div className="box post-edit" >
                            <div className="post-panel" style={{position:"fixed", left:"50px", top:"50%"}}>
                                <TextField error={!!errors.title} helperText={errors.title} style={{marginBottom: "10px"}} variant="outlined" label="Title" value={playlist.title} name="title" onChange={this.handleChange} />
                                <TextField error={!!errors.name} helperText={errors.name} style={{marginBottom: "10px"}} variant="outlined" label="Name" value={playlist.name} name="name" onChange={this.handleChange} />
                                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Desc" value={playlist.desc} name="desc" onChange={this.handleChange} />
                                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Pic" value={playlist.src} name="src" onChange={this.handleChange} />
                                <FormControl className="spacer" variant="outlined">
                                    <InputLabel>Content Ordered</InputLabel>
                                    <Select
                                        value={playlist.content_ordered}
                                        onChange={this.handleChange}
                                        label="Content Ordered"
                                        name="content_ordered"
                                    >
                                        <MenuItem value={1}>Ordered</MenuItem>
                                        <MenuItem value={2}>Random</MenuItem>
                                        <MenuItem value={3}>First Set</MenuItem>
                                        <MenuItem value={4}>First & Last</MenuItem>
                                        <MenuItem value={5}>First, Second & Last</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl className="spacer" variant="outlined">
                                    <InputLabel>Stim Ordered</InputLabel>
                                    <Select
                                        value={playlist.stim_ordered}
                                        onChange={this.handleChange}
                                        label="Stim Ordered"
                                        name="stim_ordered"
                                    >
                                        <MenuItem value={1}>Ordered</MenuItem>
                                        <MenuItem value={2}>Random</MenuItem>
                                        <MenuItem value={3}>First Set</MenuItem>
                                        <MenuItem value={4}>First & Last</MenuItem>
                                        <MenuItem value={5}>First, Second & Last</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="box persona-edit" style={{ alignItems: "center", flexDirection: "column"}}>
                                {feed.map((item, index) => (
                                    <>
                                        {page === 0 ? (
                                            <>
                                                {!item.isStim ? (
                                                    <div style={{display: "flex", justifyContent: "space-between", margin: "10px"}} className="edit-container drag-container" key={index} >
                                                        <div className="drag-panel">
                                                            <div style={{width: "69px", padding: "5px", backgroundColor: "#00b9db"}} className="drag-order">
                                                                <TextField className="spacer" style={{color: "white"}} variant="outlined" label="Order" name={item.id} value={item.order} onChange={this.handleOrderChange} />
                                                            </div>
                                                            <div className="square">
                                                                {item.src ? (
                                                                    <CardMedia
                                                                        component={item.src.split(".")[item.src.split(".").length - 1] === "mp4" ? "video" : "img" }
                                                                        image={item.src}
                                                                        title={item.title}
                                                                    />
                                                                ) : (
                                                                    <div className="noSrc">
                                                                        No Src
                                                                    </div>
                                                                ) }
                                                            </div>
                                                            <div>
                                                                <h1>{item.title}</h1>
                                                                <h2>{item.songName}</h2>
                                                            </div>
                                                        </div>
                                                        <div className="unlink">
                                                            <Button variant="contained" color="primary" onClick={() => this.unlink(item.id)}>Unlink</Button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div style={{display: "flex", justifyContent: "space-between", margin: "10px"}} className="edit-container drag-container ad-container" key={item.id} >
                                                        <div className="drag-panel">
                                                            <div className="square">
                                                                {item.src ? (
                                                                    <CardMedia
                                                                        component={item.src.split(".")[item.src.split(".").length - 1] === "mp4" ? "video" : "img" }
                                                                        image={item.src}
                                                                        title={item.title}
                                                                    />
                                                                ) : (
                                                                    <div className="noSrc">
                                                                        No Src
                                                                    </div>
                                                                ) }
                                                            </div>
                                                            <div>
                                                                <h1>{item.title}</h1>
                                                                <h2>AD WILL PLAY HERE</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                            ) : (
                                            <>
                                                {item.isStim ? (
                                                    <div style={{display: "flex", justifyContent: "space-between", margin: "10px"}} className="edit-container drag-container" key={index} >
                                                        <div className="drag-panel">
                                                            <div style={{width: "69px", padding: "5px", backgroundColor: "#00b9db"}} className="drag-order">
                                                                <TextField className="spacer" style={{color: "white"}} variant="outlined" label={feed[0].type !== 2 ? "Order" : "Time"} name={item.id} value={item.order} onChange={this.handleOrderChange} />
                                                            </div>
                                                            <div className="square">
                                                                {item.src ? (
                                                                    <CardMedia
                                                                        component={item.src.split(".")[item.src.split(".").length - 1] === "mp4" ? "video" : "img" }
                                                                        image={item.src}
                                                                        title={item.title}
                                                                    />
                                                                ) : (
                                                                    <div className="noSrc">
                                                                        No Src
                                                                    </div>
                                                                ) }
                                                            </div>
                                                            <div>
                                                                <h1>{item.title}</h1>
                                                                <h2>{item.songName}</h2>
                                                            </div>
                                                        </div>
                                                        <div className="unlink">
                                                            <Button variant="contained" color="primary" onClick={() => this.unlink(item.id)}>Unlink</Button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div style={{display: "flex", justifyContent: "space-between", margin: "10px"}} className="edit-container drag-container ad-container" key={item.id} >
                                                        <div className="drag-panel">
                                                            <div className="square">
                                                                {item.src ? (
                                                                    <CardMedia
                                                                        component={item.src.split(".")[item.src.split(".").length - 1] === "mp4" ? "video" : "img" }
                                                                        image={item.src}
                                                                        title={item.title}
                                                                    />
                                                                ) : (
                                                                    <div className="noSrc">
                                                                        No Src
                                                                    </div>
                                                                ) }
                                                            </div>
                                                            <div>
                                                                <h1>{item.title}</h1>
                                                                <h2>SONG WILL PLAY HERE</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                ))}
                            </div>
                            <div style={{width: "350px"}} />
                        </div>
                        <div className={(open ? 'user-box' : 'user-box hide')}>
                            <div className="link-container">
                                <div style={{display: "flex", padding: "20px"}}>
                                    <TextField
                                        label="Search"
                                        variant="outlined"
                                        style={{marginRight: "10px"}}
                                        onChange={(e) => this.updateSearchText(e)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                    <ToggleButtonGroup
                                        color="primary"
                                        value={currentType2}
                                        exclusive
                                        onChange={this.updateSearchType2}
                                        style={{marginLeft: "10px"}}
                                    >
                                        <ToggleButton value="new">New</ToggleButton>
                                        <ToggleButton value="old">Old</ToggleButton>
                                    </ToggleButtonGroup>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={currentType3}
                                        exclusive
                                        onChange={this.updateSearchType3}
                                        style={{marginLeft: "10px"}}
                                    >
                                        <ToggleButton value="all">All</ToggleButton>
                                        <ToggleButton value="sti">Stims</ToggleButton>
                                        <ToggleButton value="nsti">Non-Stims</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>

                                <div className="profiles-container" style={{justifyContent: "left", marginLeft: 0, width: "100%"}}>
                                    {currentItems.map((item) => (
                                        <Card key={item.id}
                                              style={{width: "340px", marginBottom: "20px", backgroundColor: "#424242", marginLeft: "17px", position: "relative"}}>
                                            {item.status === 4 && (
                                                <div className="src-warning">
                                                    <Tooltip title={"Source Error"}><GppMaybeIcon color="error" /></Tooltip>
                                                </div>
                                            )}
                                            <CardActionArea>
                                                {item.src ? (
                                                    <CardMedia
                                                        className={currentFeed.filter(o => {return o.id === item.id })[0] ? "onFeed" : ""}
                                                        component={item.src.split(".")[item.src.split(".").length - 1] === "mp4" ? "video" : "" }
                                                        style={{height: "140px"}}
                                                        image={item.src}
                                                        title={item.title}
                                                    />
                                                ) : (
                                                    <div style={{color: "#ffffff85", fontWeight: 700, fontSize: "30px", height: "140px"}}>
                                                        {currentFeed.filter(o => {return o.id === item.id })[0] ? "IN FEED" : "WHERE'S THE LAMB SAUCE"}
                                                    </div>
                                                ) }
                                                <CardContent style={{backgroundColor: "#515151", height: "80px"}}>
                                                    <div style={{color: "rgba(255, 255, 255, 0.7)", fontSize: "18px"}}>{item.title}</div>
                                                    <div style={{color: "rgba(255, 255, 255, 0.7)", fontSize: "18px"}}>{item.songName}</div>
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions>
                                                <Button disabled={currentFeed.filter(o => {return o.id === item.id })[0]} size="small" variant="contained" color="secondary" onClick={() => this.link(item.id)}>
                                                    ADD
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    ))}
                                </div>
                                <div style={{padding: "5px", alignSelf: "center"}}>
                                    <Pagination count={Math.ceil(searchContent.length / itemsPerPage)} page={currentPage}
                                                variant="outlined" onChange={(event, page) => this.setPages(event, page)}/>
                                    <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                        <Button variant="contained" color="primary"
                                                onClick={this.toggleOpen}>Close</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="links">
                            <Button variant="contained" color="primary" className="spacer" onClick={() => this.toggleOpen()} >Link</Button>
                            <Link to="/playlist"><Button disabled={posting} variant="contained" color="primary" className="spacer">Back</Button></Link>
                            <Button disabled={posting} variant="contained" color="primary" onClick={() => this.updatePlaylist()} >Save</Button>
                        </div>
                    </div>
                </>
            )
        }
    }
}

export default withSnackbar(PlaylistEdit)
